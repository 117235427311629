//import React , { useEffect , useState } from "react";
//import { useRoute } from '@react-navigation/native';

export async function UpdateData(prop) {
        
    try {
      const rawResponse = await fetch(process.env.REACT_APP_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "authToken": localStorage.getItem("id"), 
        },
        body: JSON.stringify(prop),
      });

      if (!rawResponse.ok) {        
        throw new Error('La solicitud no fue exitosa. Código de estado: ' + rawResponse.status);
      }

      const data = await rawResponse.json();       
      return data; // Retorna los datos para usarlos en otra parte si es necesario

    } catch (error) {      
      console.error("Error en la solicitud:", error);
    }
  }

export async function UpdateTicket(prop) {
    
    UpdateData(prop);    

}
