import React from "react";
import { Text, ScrollView, StyleSheet , TouchableWithoutFeedback, View, TouchableOpacity  } from "react-native";
//import { FaArrowLeft, FaArrowRight } from '@react-icons/all-files/fa';
import StyledText from "./StyledText";
//import  Constants  from "expo-constants";
import theme from "../theme";
import { Link, useLocation } from "react-router-dom";
import { useNavigation } from '@react-navigation/native';
import { BiChevronRightCircle, BiChevronLeftCircle, BiRefresh,  BiLogOut } from "react-icons/bi";

//theme.colors.primary,

const styles = StyleSheet.create({
    container: {
        backgroundColor: "#0076A9", 
        flexDirection: "row",
        paddingTop: 10,
        paddingBottom: 10,
        paddingHorizontal: 16,
        justifyContent: "space-between",
        alignItems: "center", 
      },
      text: {
        color: theme.colors.textSecondary,
        paddingHorizontal: 10,
        fontSize: 16,
      },
      text2: {
        color: "white",
        paddingHorizontal: 10,
        fontSize: 16,
      },
      scroll: {
        flexGrow: 1, 
      },
      active: {
        color: theme.colors.textPrimary,
        fontWeight: "bold", 
      },
      signOutTab: {
        marginLeft: 16,
        color: "white",
      },
      refreshButton: {
        alignItems: 'center',
        //backgroundColor: 'white',
        color: 'white',
        paddingVertical: 6,
        paddingHorizontal: 12,
        borderRadius: 5,
    },
    refreshButtonText: {
        color: '#3498db',
        fontSize: 14,
        fontWeight: 'bold',
    },
    arrowContainer: {
      flexDirection: 'row', 
      alignItems: 'center',
    }


})

const AppBarTab = ({ children , to, onPress }) => {
    
    let active = false;

    const textStyles = [
        styles.text2,
        active && styles.active
    ]

    if (onPress) {
        return (
          <TouchableOpacity onPress={onPress}>
            <StyledText fontWeight="bold" style={textStyles}>
              {children}
            </StyledText>
          </TouchableOpacity>
        );
      }

    return(
        <Link to={to} component={TouchableWithoutFeedback}>
            <StyledText fontWeight="bold" style={textStyles}>
                    {children}
            </StyledText>
        </Link>
    )
}


const AppBar = ({ handleRefreshButton }) => {   
    const navigation = useNavigation();
    const handleSignOut = () => {
        navigation.navigate('LogInPage');
      };

      const navStyle = [
        styles.container
      ]

    return (
        <View style={navStyle}>
        <ScrollView horizontal showsHorizontalScrollIndicator={false} contentContainerStyle={styles.scroll}>
            <AppBarTab to="/">

              <svg width="30%" viewBox="0 0 104 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M57.7517 18.4669H51.6122C49.3183 18.4669 47.1594 17.4986 45.7426 15.9769C44.1908 14.4553 35.2177 0.553314 35.2177 0.553314C35.0828 0.345821 34.8129 0.207493 34.5431 0.207493H30.1577C29.8204 0.207493 29.5505 0.345821 29.4156 0.622478C28.8758 1.38329 19.1606 14.732 17.6763 16.1844C16.1921 17.7061 14.1006 18.6744 11.8067 18.6744C8.1635 18.6744 5.26242 15.7003 5.26242 11.9654C5.26242 8.57637 7.82616 5.74063 11.0646 5.32565C11.4694 5.25648 11.8067 4.91066 11.8067 4.49568V0.899135C11.8067 0.414986 11.4019 0 10.9296 0C4.79015 0.48415 0 5.67147 0 11.9654C0 18.6052 5.26242 24 11.8067 24C15.5849 24 19.2281 22.4092 21.387 19.9885C23.2761 17.9135 29.8878 8.92219 31.7769 6.29395C31.9118 6.15562 32.0468 5.94813 32.2492 5.94813C32.4516 5.94813 32.654 6.15562 32.7889 6.29395C34.4756 8.92219 40.0079 17.6369 41.8969 19.7118C44.1234 22.1326 47.6991 23.7233 51.4773 23.7233H57.4818C57.5493 23.7233 57.6167 23.7233 57.6842 23.7233C58.1565 23.7233 58.5613 23.3084 58.5613 22.8242V19.366C58.6287 18.8818 58.2239 18.4669 57.7517 18.4669Z" fill="white"></path>
                  <path d="M88.9231 0.137451H88.4508C88.3833 0.137451 88.3159 0.206615 88.3159 0.27578C88.3159 0.967422 88.3159 4.35647 88.3159 4.35647V4.42564V4.4948C88.2484 5.32477 87.8436 5.87809 87.1015 5.87809C86.5617 5.87809 86.2918 5.73976 85.887 5.25561L85.8196 5.18644C85.7521 5.11728 85.7521 5.11728 85.6846 5.04812C85.6172 5.04812 85.6172 5.04811 85.5497 5.11728C85.4822 5.11728 85.2798 5.32477 85.2124 5.39394C85.1449 5.4631 85.1449 5.4631 85.1449 5.53226C85.1449 5.53226 85.1449 5.60143 85.2124 5.60143C85.6846 6.29307 86.2918 6.63889 87.1015 6.63889C87.6412 6.63889 88.1135 6.4314 88.5183 6.08558C88.9231 5.67059 89.1255 5.11728 89.1255 4.42564V0.344944C89.058 0.206615 88.9905 0.137451 88.9231 0.137451Z" fill="white"></path>
                  <path d="M82.7846 4.21978H80.0859L81.4353 1.10739L82.7846 4.21978ZM83.5942 4.21978C82.8521 2.55984 82.0425 0.692402 81.8401 0.277416C81.8401 0.208252 81.7726 0.208252 81.7051 0.208252H81.1654C81.0979 0.208252 81.0305 0.277416 81.0305 0.277416C80.7606 0.899895 78.8715 5.04975 78.3318 6.29471C78.3318 6.36387 78.3318 6.36387 78.3318 6.43304C78.3318 6.5022 78.3993 6.5022 78.4667 6.5022H78.939C79.0065 6.5022 79.1414 6.43304 79.1414 6.36387L79.7486 4.98059H83.122L83.7292 6.36387C83.7966 6.43304 83.8641 6.5022 83.9316 6.5022H84.4038C84.4713 6.5022 84.4713 6.5022 84.5388 6.43304C84.5388 6.36387 84.5388 6.36387 84.5388 6.29471C84.3364 5.87972 83.999 5.11892 83.5942 4.21978Z" fill="white"></path>
                  <path d="M94.5874 4.21954H91.8887L93.2381 1.10714L94.5874 4.21954ZM96.2741 6.29446C96.0717 5.87948 95.7344 5.11867 95.3296 4.21954C94.5874 2.55959 93.7778 0.692158 93.5754 0.277172C93.5754 0.208008 93.5079 0.208008 93.4405 0.208008H92.9007C92.8333 0.208008 92.7658 0.277172 92.7658 0.277172C92.4959 0.899651 90.6069 5.04951 90.0671 6.29446C90.0671 6.36363 90.0671 6.36363 90.0671 6.43279C90.0671 6.50196 90.1346 6.50196 90.2021 6.50196H90.6743C90.7418 6.50196 90.8767 6.43279 90.8767 6.36363L91.4839 4.98034H94.8573L95.4645 6.36363C95.532 6.43279 95.5994 6.50196 95.6669 6.50196H96.1392C96.2066 6.50196 96.2066 6.50196 96.2741 6.43279C96.3416 6.36363 96.3416 6.29446 96.2741 6.29446Z" fill="white"></path>
                  <path d="M77.4514 5.39481C77.4514 5.39481 77.3839 5.32565 77.3839 5.25648C77.3165 5.18732 77.249 5.11816 77.249 5.11816C77.1815 5.04899 77.1141 5.04899 77.1141 5.04899C77.0466 5.04899 77.0466 5.04899 77.0466 5.11816C76.4394 5.67147 75.8997 5.87896 75.1575 5.87896C73.8082 5.87896 72.7962 4.77233 72.7962 3.31989C72.7962 1.86744 73.8082 0.760807 75.1575 0.760807C75.8322 0.760807 76.3719 0.9683 76.9117 1.45245C76.9791 1.52161 76.9791 1.52161 77.0466 1.52161C77.1141 1.52161 77.1815 1.52161 77.249 1.45245C77.249 1.38329 77.5189 1.10663 77.5189 1.03746C77.5189 1.03746 77.5863 0.9683 77.5189 0.899135C77.5189 0.899135 77.4514 0.829971 77.3839 0.760807C76.7093 0.207493 76.0346 0 75.1575 0C73.3359 0 71.9866 1.45245 71.9866 3.31989C71.9866 5.18732 73.3359 6.63977 75.1575 6.63977C76.1021 6.63977 76.7767 6.36311 77.5189 5.67147L77.5863 5.60231C77.5863 5.53314 77.5189 5.53314 77.4514 5.39481Z" fill="white"></path>
                  <path d="M75.6973 17.4294H74.7527C74.6853 17.4294 74.5503 17.4986 74.5503 17.5678C74.213 18.3286 72.6612 21.9251 72.1215 23.1701L71.9866 23.4467C71.9866 23.5159 71.9866 23.5851 71.9866 23.6542C71.9866 23.7234 72.054 23.7234 72.1215 23.7234H73.3359C73.4034 23.7234 73.5383 23.6542 73.5383 23.5851L75.225 19.6427L76.9117 23.5851C76.9791 23.6542 77.0466 23.7234 77.1141 23.7234H78.261C78.3285 23.7234 78.3959 23.7234 78.3959 23.6542C78.3959 23.5851 78.4634 23.5159 78.3959 23.4467C77.9237 22.4093 76.1695 18.3977 75.8322 17.5678C75.8997 17.4986 75.8322 17.4294 75.6973 17.4294Z" fill="white"></path>
                  <path d="M97.2873 17.4294H93.1044C92.9694 17.4294 92.902 17.4986 92.902 17.6369C92.902 18.1211 92.902 19.6427 92.902 20.5418C92.902 21.5793 92.902 22.8243 92.902 23.4467C92.902 23.5159 92.902 23.5851 92.9694 23.5851C93.0369 23.6542 93.0369 23.6542 93.1044 23.6542H97.2873C97.4223 23.6542 97.4897 23.5851 97.4897 23.4467V22.6168C97.4897 22.4784 97.3548 22.4093 97.2873 22.4093H94.3188V21.1643C94.5887 21.1643 96.2078 21.1643 96.7476 21.1643C96.8825 21.1643 96.95 21.0951 96.95 20.9568V20.1268C96.95 19.9885 96.8825 19.9194 96.7476 19.9194H94.3188V18.7436H97.2873C97.4223 18.7436 97.4897 18.6052 97.4897 18.5361V17.7061C97.4897 17.4986 97.4223 17.4294 97.2873 17.4294Z" fill="white"></path>
                  <path d="M101.268 19.9179C100.189 19.6413 99.9187 19.503 99.9187 19.088C99.9187 18.7421 100.189 18.5347 100.661 18.5347C101.066 18.5347 101.605 18.7421 102.145 19.088H102.213C102.28 19.088 102.348 19.0188 102.415 18.9496C102.482 18.8805 102.685 18.6038 102.82 18.3963C102.887 18.3272 102.955 18.258 102.955 18.1888C103.022 18.1197 103.09 17.9813 102.955 17.9122C102.28 17.428 101.538 17.1514 100.728 17.1514C99.4465 17.1514 98.5019 17.9813 98.5019 19.088C98.5019 20.4021 99.4465 20.7479 100.593 21.0937C101.673 21.3704 101.808 21.5779 101.808 21.8545C101.808 22.2004 101.47 22.4079 100.931 22.4079C100.391 22.4079 99.7838 22.2004 99.2441 21.7162C99.1091 21.647 98.9742 21.647 98.9067 21.7854C98.8393 21.8545 98.6369 22.1312 98.5019 22.3387L98.2995 22.6154C98.2995 22.6845 98.232 22.6845 98.2995 22.7537C98.2995 22.8228 98.367 22.892 98.5019 22.9612C98.5019 22.9612 98.5694 23.0303 98.6369 23.0303C99.3115 23.5145 100.121 23.722 100.931 23.722C102.348 23.722 103.292 22.9612 103.292 21.7162C103.292 20.6787 102.482 20.1946 101.268 19.9179Z" fill="white"></path>
                  <path d="M84.939 17.4292H83.9945C83.8596 17.4292 83.7921 17.5675 83.7921 17.6367V21.1641L81.026 17.4984C80.9585 17.4292 80.891 17.4292 80.8236 17.4292H79.879C79.7441 17.4292 79.6766 17.5675 79.6766 17.6367V23.5157C79.6766 23.654 79.7441 23.7231 79.879 23.7231H80.8236C80.9585 23.7231 81.026 23.654 81.026 23.5157C81.026 22.6857 81.026 20.4033 81.026 19.8499L83.8596 23.654C83.927 23.7231 83.9945 23.7231 84.062 23.7231H84.939C85.074 23.7231 85.1414 23.654 85.1414 23.5157V17.6367C85.1414 17.4984 85.074 17.4292 84.939 17.4292Z" fill="white"></path>
                  <path d="M88.9186 22.409H87.9066V18.7433H88.9186C89.9306 18.7433 90.5378 19.435 90.5378 20.6108C90.6053 21.7174 89.9981 22.409 88.9186 22.409ZM88.9186 17.4292H86.6922C86.6247 17.4292 86.5572 17.4292 86.5572 17.4984C86.4898 17.5675 86.4897 17.5675 86.4897 17.6367C86.4897 17.8442 86.4897 18.2592 86.4897 18.4667V22.6165C86.4897 22.824 86.4897 23.1007 86.4897 23.3082V23.5157C86.4897 23.654 86.6247 23.7231 86.6922 23.7231H88.9186C90.7402 23.7231 92.0221 22.409 92.0221 20.5416C92.0221 19.6425 91.7522 18.8816 91.2125 18.3283C90.6727 17.7059 89.8631 17.4292 88.9186 17.4292Z" fill="white"></path>
                  <path d="M77.1814 13.7632H74.2803V9.06003C74.2803 8.9217 74.1454 8.85254 74.0779 8.85254H73.0659C72.931 8.85254 72.8635 8.99087 72.8635 9.06003V14.8698C72.8635 15.0082 72.9985 15.0773 73.0659 15.0773H77.1814C77.3163 15.0773 77.3838 14.939 77.3838 14.8698V13.9707C77.3838 13.8324 77.3163 13.7632 77.1814 13.7632Z" fill="white"></path>
                  <path d="M79.4081 11.8953C79.4081 10.7886 80.2177 9.95865 81.2297 9.95865C82.2417 9.95865 83.0513 10.7886 83.0513 11.8953C83.0513 13.0019 82.2417 13.8319 81.2297 13.8319C80.2177 13.901 79.4081 13.071 79.4081 11.8953ZM81.2297 8.64453C79.3406 8.64453 77.8563 10.097 77.8563 11.9644C77.8563 13.8319 79.2731 15.2843 81.2297 15.2843C83.1187 15.2843 84.603 13.8319 84.603 11.9644C84.603 10.0278 83.1862 8.64453 81.2297 8.64453Z" fill="white"></path>
                  <path d="M88.1812 11.2736C87.1018 10.997 86.8319 10.8587 86.8319 10.4437C86.8319 10.0979 87.1018 9.89037 87.574 9.89037C87.9788 9.89037 88.5186 10.0979 89.0583 10.4437H89.1258C89.1933 10.4437 89.2607 10.3745 89.3282 10.3054C89.3957 10.2362 89.5306 10.0287 89.6655 9.8212C89.733 9.68287 89.8005 9.61371 89.8005 9.54454C89.8679 9.47538 89.9354 9.33705 89.8005 9.26789C89.1258 8.78374 88.3836 8.50708 87.574 8.50708C86.2922 8.50708 85.3476 9.33705 85.3476 10.4437C85.3476 11.7578 86.2922 12.1036 87.4391 12.4494C88.5186 12.7261 88.6535 12.9336 88.6535 13.2103C88.6535 13.5561 88.3162 13.7636 87.7764 13.7636C87.2367 13.7636 86.6295 13.5561 86.0898 13.0719C85.9548 13.0028 85.8199 13.0028 85.7524 13.1411C85.685 13.2102 85.4826 13.4869 85.3476 13.6944L85.1452 13.9711C85.1452 14.0402 85.0778 14.0402 85.1452 14.1094C85.1452 14.1785 85.2802 14.2477 85.3476 14.3169H85.4151L85.4826 14.386C86.1572 14.8702 86.9668 15.0777 87.7764 15.0777C89.1933 15.0777 90.1378 14.3169 90.1378 13.0719C90.2053 12.0345 89.3957 11.6195 88.1812 11.2736Z" fill="white"></path>
                  <path d="M65.1719 23.7945H61.6636C61.1913 23.7945 60.7865 23.3795 60.7865 22.8953V19.2988C60.7865 18.8146 61.1913 18.3997 61.6636 18.3997H65.1719C65.6441 18.3997 66.0489 18.8146 66.0489 19.2988V22.8953C66.0489 23.3795 65.6441 23.7945 65.1719 23.7945Z" fill="#F9BE00"></path>
              </svg>


            </AppBarTab>
        </ScrollView>
        <View style={styles.arrowContainer}>
          <TouchableOpacity style={styles.refreshButton} onPress={ () => handleRefreshButton("previous")}>
              
              <BiChevronLeftCircle></BiChevronLeftCircle>
          </TouchableOpacity>

          <TouchableOpacity style={styles.refreshButton} onPress={ () => handleRefreshButton("next")}>
            <BiChevronRightCircle></BiChevronRightCircle>
          </TouchableOpacity>
        </View>
        <TouchableOpacity style={styles.refreshButton} onPress={() => handleRefreshButton("refresh")}>
          <BiRefresh></BiRefresh>
        </TouchableOpacity>
        <AppBarTab onPress={handleSignOut} style={styles.signOutTab} >
            <BiLogOut></BiLogOut>
        </AppBarTab>
        </View>
    )
}

export default AppBar